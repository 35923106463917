import React, { useState, useEffect } from 'react';
import './RequestedBooks.css';

function RequestedBooks() {
    const [requests, setRequests] = useState([]);
    const [statusUpdates, setStatusUpdates] = useState({}); // To hold the selected status for each request

    useEffect(() => {
        fetchRequests();
    }, []);

    const fetchRequests = async () => {
        try {
            const response = await fetch('https://api.digitalaalim.in/admin/book-requests/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                }
            });
            if (response.ok) {
                const data = await response.json();
                setRequests(data);
            } else {
                console.error('Failed to fetch requests');
            }
        } catch (error) {
            console.error('Error fetching requests:', error);
        }
    };

    const handleStatusChange = (event, requestId) => {
        setStatusUpdates({
            ...statusUpdates,
            [requestId]: event.target.value,
        });
    };

    const updateRequestStatus = async (requestId) => {
        const selectedStatus = statusUpdates[requestId];
        if (!selectedStatus) {
            alert('Please select a status to update.');
            return;
        }

        try {
            const response = await fetch('https://api.digitalaalim.in/admin/update-book-request/', {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: selectedStatus,
                    request_id: requestId,
                }),
            });
            if (response.ok) {
                alert('Request status updated successfully');
                setRequests(requests.map(request =>
                    request.request_id === requestId ? { ...request, status: selectedStatus } : request
                ));
            } else {
                console.error('Failed to update request status');
            }
        } catch (error) {
            console.error('Error updating request status:', error);
        }
    };

    return (
        <div className="requested-books-container">
            <h1>Requested Books</h1>
            {requests.length > 0 ? (
                <table className="requests-table">
                    <thead>
                    <tr>
                        <th>Request ID</th>
                        <th>Bot ID</th>
                        <th>Bot Name</th>
                        <th>Book Name</th>
                        <th>Author</th>
                        <th>Category</th>
                        <th>Requested At</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {requests.map(request => (
                            <tr key={request.request_id}>
                                <td>{request.request_id}</td>
                                <td>{request.bot_id}</td>
                                <td>{request.bot_name}</td>
                                <td>{request.book_name}</td>
                                <td>{request.book_author}</td>
                                <td>{request.book_category}</td>
                                <td>{new Date(request.requested_at).toLocaleString()}</td>
                                <td>{request.status}</td>
                                <td>
                                    <select
                                        value={statusUpdates[request.request_id] || request.status}
                                        onChange={(e) => handleStatusChange(e, request.request_id)}
                                        className="status-select"
                                    >
                                        <option value="fulfilled">Fulfilled</option>
                                        <option value="rejected">Rejected</option>
                                    </select>
                                    <button
                                        onClick={() => updateRequestStatus(request.request_id)}
                                        className="update-status-btn"
                                    >
                                        Update Status
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No book requests found.</p>
            )}
        </div>
    );
}

export default RequestedBooks;
