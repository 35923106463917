import { useState, useEffect } from 'react';
import "./toggleSignup.css";

const ToggleSignup = () => {
    const [signupEnabled, setSignupEnabled] = useState(null); // Set initial state to null until we fetch
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch the current signup status on component load
        const fetchSignupStatus = async () => {
            const accessToken = localStorage.getItem('accessToken');
            try {
                const response = await fetch(`https://api.digitalaalim.in/admin/signup-status/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'x-api-key': 'SU_b399f6776c0525a5880a86c05ef80f8fc95538b5fed1c8a6825898d60e48b804', // API key if needed
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setSignupEnabled(data.signup_enabled); // Set initial state based on fetched data
                } else {
                    console.error('Failed to fetch signup status');
                }
            } catch (error) {
                console.error('Error fetching signup status:', error);
            }
        };

        fetchSignupStatus();
    }, []);

    const toggleSignup = async () => {
        const accessToken = localStorage.getItem('accessToken');
        const apiUrl = `https://api.digitalaalim.in/admin/toggle-signup/?enable=${!signupEnabled}`; // API URL with toggle logic

        try {
            setIsLoading(true);

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'x-api-key': 'SU_b399f6776c0525a5880a86c05ef80f8fc95538b5fed1c8a6825898d60e48b804', // API key if needed
                },
            });

            if (response.ok) {
                setSignupEnabled(!signupEnabled); // Toggle the state after a successful response
                alert(`Signup has been ${signupEnabled ? 'disabled' : 'enabled'}.`);
            } else {
                alert('Failed to toggle signup.');
            }
        } catch (error) {
            console.error('Error toggling signup:', error);
            alert('Error toggling signup. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="setting-card">
            <h2 className="title">Toggle Signup</h2>
            {signupEnabled !== null ? (
                <button onClick={toggleSignup} disabled={isLoading} className={`toggle-btn ${signupEnabled ? 'enabled' : 'disabled'}`}>
                    {isLoading ? 'Processing...' : signupEnabled ? 'Disable Signup' : 'Enable Signup'}
                </button>
            ) : (
                <p>Loading signup status...</p>
            )}
        </div>
    );
};

export default ToggleSignup;
