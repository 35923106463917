import React, {useState} from 'react';
import './Dashboard.css';

const AddBook = () => {
    const [formData, setFormData] = useState({
        name: '',
        author: '',
        volume: '',
        sect: '',
        type: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem('accessToken');
        const apiUrl = `https://api.digitalaalim.in/admin/add-book/`;

        try {
            setIsLoading(true);

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                alert('Book added successfully');
                setFormData({
                    name: '',
                    author: '',
                    volume: '',
                    sect: '',
                    type: ''
                }); // Reset form after success
            } else {
                alert('Failed to add book.');
            }
        } catch (error) {
            console.error('Error adding book:', error);
            alert('Error adding book. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="setting-card">
            <h2 className="title">Add New Book</h2>
            <form onSubmit={handleSubmit} className="add-book-form">

                <input
                    type="text"
                    name="name"
                    placeholder="Book Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="author"
                    placeholder="Author"
                    value={formData.author}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="volume"
                    placeholder="volume"
                    value={formData.volume}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="sect"
                    value={formData.sect}
                    placeholder="Sect"
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="type"
                    placeholder="Type"
                    value={formData.type}
                    onChange={handleInputChange}
                    required
                />

                <button type="submit" disabled={isLoading} className="submit-btn">
                    {isLoading ? 'Adding...' : 'Add Book'}
                </button>
            </form>
        </div>
    )
        ;
};

export default AddBook;
